<template>
    <div class="dynamic-page">
        <div class="container">
            <div class="breadcrump">
                <h4 class="py-3 mb-4">
                    <span class="text-muted fw-light">
                        <a href="/">{{ $j('Homepage') }}</a> <i class="mdi mdi-chevron-right"></i>
                    </span> Hamkorlar
                </h4>
            </div>
            <div class="partners pt-3 pb-5">
                <h2 class="mb-3 text-center">Mahalliy hamkorlar</h2>
                <div class="row pt-3 ml-0">
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                </div>
                <div class="row ml-0">
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                </div>
                <div class="row ml-0">
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/6.png" width="65">
                            <span>O’zbek film milliy agentligi</span>
                        </div>
                    </div>
                </div>
                <h2 class="mb-3 pt-5 text-center">Xorijiy hamkorlar</h2>
                <div class="row pt-3 ml-0">
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/7.png" width="65">
                            <span>Universal Pictuters</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/7.png" width="65">
                            <span>Universal Pictuters</span>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card-part">
                            <img src="/assets/img/partners/7.png" width="65">
                            <span>Universal Pictuters</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {};
</script>